:root {
  --bg-color: #0c0c0c;
  --primary-color: #0d0d0d;
  --secondary-color: #fff;
  --text-color: #fff;
  --text-color-2: #fff;
  --text-color-3: rgb(204, 0, 0);
  --overlay-color: rgb(12 12 12 / 63%);
}

html {
  /* height: 100%; */
  font-family: 'Rubik', sans-serif;
}

body {
  margin: 0;
  /* height: 100%; */
  overflow-x: hidden;
  overflow-y: visible;
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

p {
  word-break: break-word;
  hyphens: none;
}


/* 
<uniquifier>: Use a unique and descriptive class name
<weight>: Use a value from 300 to 900 
*/

/* .rubik-text {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
} */