.roadmap-header{
    font-family: "Rubik", sans-serif;
    font-size: 2.75em;
    color: #EC8731;
}

.roadmap-container{
    min-width: 425px;
    max-width: 450px;
    align-self: center;
}


@media screen and (max-width: 576px) {
    .roadmap-container {
        font-size: 4vw;
        min-width: 100px;
        max-width: 80vw;
    }
}