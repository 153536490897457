/* 
<uniquifier>: Use a unique and descriptive class name
<weight>: Use a value from 300 to 900 
*/

/* .rubik-text {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
} */

.nav-text-link {
    font-family: "Rubik", sans-serif;
    color: var(--text-color);
    text-decoration: none;
    /* font-size: 28px; */
    font-style: normal;
    font-size: 1.25rem;
    line-height: 2;
}

.navbar-background {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    min-height: 110px;
    background-size: cover; /* Ensures the background covers the entire element */
    background-repeat: no-repeat; /* Prevents the background from repeating */
}

/* Adjust for mobile screens */
@media (max-width: 768px) {
    .navbar-background {
        background-size: auto 100%; /* Scale height automatically to maintain full width */
        min-height: 12vh;
    }
}

.nav-text-link:hover {
    color: var(--text-color-3);
    cursor: pointer;
}

.active {
    color: var(--text-color-3);
}

.jit-logo {
    padding-top: 10px;
    padding-bottom: 10px;
    width: auto;
    height: 70px;
}

@media screen and (max-width: 576px) {
    .nav-text-link {
        font-size: 4vw;
    }

    .jit-logo {
        width: 12vw;
        height: 12vw;
    }
}