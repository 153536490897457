.critical-background {
margin-top: 100px;
    top: 0;
    left: 0;
    right: 0;
    min-height: 294px;
    background-size: auto 100%; /* Maintain original height, scale width on desktop */
}

.critical-icons {
    display:flex;
    justify-content:'center';
    align-items:'center';
}

@media (max-width: 576px) {
    .critical-icons {
        display:flex;
        flex-direction: column;
        justify-content:'center';
        align-items:'center';
    }
}
