.jit-video {
    width: 400px;
    height: 400px;
    margin-top: 8vh;
    min-width: 400px;
}

.about-text-container
{
    display: flex;
    width:40vw;
    min-width: 425px;
}

@media (max-width: 576px) {
    .jit-video {
        width: 80vw;
        height: 80vw;
        margin-top: 8vh;
   
    }
    .about-text-container {
        display: flex;
        width:90vw;
        min-width: 100px;
    }
}

.about-title {
    font-family: "Rubik", sans-serif;
    font-size: 2.75em;
    color: #EC8731;
}

.about-content {
    font-family: "Rubik", sans-serif;
}
